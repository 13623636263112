<template>
	<v-container fluid>
		<div class="mb-4 text-right">
			<v-btn rounded color="primary" dark @click="createReport()">
				+ Create Report
			</v-btn>
		</div>

		<div v-if="reports.length > 0">
			<ReportCard
				v-for="report in reports"
				:key="report.id"
				v-bind="report"
			></ReportCard>
		</div>
		<NoData v-else></NoData>

		<Paginator
			:paginator="paginator"
			:url="getUrl()"
			@update="changePage($event)"
		></Paginator>
	</v-container>
</template>

<script>
import Paginator from '../../components/Paginator'
import ReportCard from './components/ReportCard'
import NoData from '../../components/NoData'
export default {
	data() {
		return {}
	},
	components: {
		ReportCard,
		NoData,
		Paginator,
	},
	created() {
		this.$store.dispatch('report/getListingData')
	},
	
	computed: {
		reports: {
			get() {
				return this.$store.state.report.reports
			},
			set(value) {
				this.updateReports(value)
			},
		},
		paginator: {
			get() {
				return this.$store.state.report.paginator
			},
			set(value) {
				this.updatePaginator(value)
			},
		},
	},
	methods: {
		updateReports(value) {
			this.$store.commit('report/updateReports', value)
		},
		updatePaginator(value) {
			this.$store.commit('report/updatePaginator', value)
		},
		getUrl() {
			const projectId = this.$store.state.app.projectId
			let url = `/${projectId}/reports`
			return url
		},
		changePage(data) {
			this.reports = data.data
			this.paginator = data.paginator
		},
		createReport() {
			this.$router.push({
				name: 'report.create',
			})
		},
	},
}
</script>
