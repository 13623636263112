<template>
	<v-card class="mx-auto" max-width="900" outlined>
		<v-row class="ma-0 pa-0 d-flex flex-row">
			<v-col cols="9" class="px-4 py-3">
				<div class="d-flex align-start justify-space-between">
					<h3 class="title" color="#5F615A">
						{{ title }}
					</h3>
				</div>

				<!-- <div class="d-flex flex-row align-start justify-space-between">
					<div class="d-flex align-start">
						<div class="d-flex flex-column">
							<a class="author"
								>{{ author.firstName }} {{ author.lastName }}</a
							>
							<span v-if="updatedAt" class="date">
								Updated on {{ dateFormat(updatedAt) }}
							</span>
						</div>
					</div>
				</div> -->
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<!--Action Buttons-->
		<v-card-actions class="px-6 py-4 card-actions">
			<UnoEditButton
				:to="{ name: 'report.edit', params: { id } }"
			></UnoEditButton>

			<v-spacer></v-spacer>
			<UnoDeleteButton
				:deleting="deleting"
				@confirm="deleteReport()"
			></UnoDeleteButton>
		</v-card-actions>
	</v-card>
</template>

<script>
// ACTION BUTTONS
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'

export default {
	data() {
		return {
			deleting: false,
		}
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		updatedAt: {
			type: String,
		},
	},
	components: {
		UnoEditButton,
		UnoDeleteButton,
	},
	// computed: {

	// },
	methods: {
		editReport(id) {
			this.$router.push({
				name: 'report.edit',
				params: { id: id },
			})
		},
		deleteReport() {
			this.deleting = true
			this.$store.dispatch('report/deleteReport', {
				reportId: this.id,
			})
		},
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
	},
}
</script>
<style scoped>
.image-wrapper {
	overflow: hidden;
	border: 1px solid #eee;
	max-height: 200px !important;
	height: 100%;
}
.image-wrapper .v-responsive {
	height: 100% !important;
}
.title a {
	font-size: 21px !important;
	line-height: 1.4 !important;
	color: black !important;
	text-decoration: none !important;
}
.title a:hover {
	color: var(--blue) !important;
}
.description {
	font-size: 18px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.author {
	font-size: 17px;
	font-weight: 400;
	color: var(--blue);
}
.author:hover {
	text-decoration: underline;
}
.date {
	font-size: 15px;
	font-weight: 400;
	color: #808080eb;
}
.v-chip {
	min-width: 121px !important;
}
</style>
